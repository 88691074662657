<template>
	<div class="home">
		<table-alphataxcode></table-alphataxcode>
	</div>
</template>

<script>
import tableAlphataxcode from '@/components/pages/maint/alphataxcode/tableAlphataxcode.vue';

export default {
	components: {
		'table-alphataxcode': tableAlphataxcode
	}
};
</script>

<style>

</style>
