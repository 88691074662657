<template>
     <v-dialog v-model="dialog" persistent max-width="50%" scrollable>
      <template v-slot:activator="{ on }">
          <app-add-button v-bind:module="'alphataxcode'"></app-add-button>
        </template>
        <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
           >
         <v-card title>
            <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                    <v-flex xs12 sm6 md6>
                      <v-text-field 
                        v-model="editedItem.atc_code"
                        :counter="5"
                        :rules="atc_codeRules"
                        label="ATC Code"
                        required
                        maxlength=5
                  ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                     <v-text-field 
                    v-model="editedItem.atc_desc"
                    :counter="250"
                    :rules="atc_desc_Rules"
                    label="ATC Description"
                    required
                    maxlength=250
                ></v-text-field>
                   </v-flex>
                   <v-flex xs12 sm6 md6>
                    <v-text-field 
                    v-model="editedItem.atc_rate"
                    :counter="2"
                    label="ATC Rate"
                    required
                    maxlength=2
                ></v-text-field>
                </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
              <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
            </v-card-actions>
           </v-card>
         </v-form>
        </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';


export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		search: null,
		isvalid: true,
		atc_codeRules: [
			v => !!v || 'ATC Code is required.',
		],
		atc_desc_Rules: [
			v => !!v || 'ATC Description',
			v => (v && v.length <= 250) || 'ATC Description must be less than 250 characters',
		],
      
	}),
	computed: {
		...mapGetters({
			alphataxcodes: 'alphataxcode/alphataxcodes',
			atcCode: 'alphataxcode/atcCode',
			editedItem: 'alphataxcode/editedItem',
			defaultItem: 'alphataxcode/defaultItem',
			dialog: 'alphataxcode/dialog',
			editedIndex: 'alphataxcode/editedIndex',
			formTitle: 'alphataxcode/formTitle',
			valid: 'alphataxcode/valid',
			currUser: 'auth/currUser'
		})
	}, 
	methods: {
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('alphataxcode/setValid',true);
				this.$store.dispatch('alphataxcode/setDialog',false);
				this.$store.dispatch('alphataxcode/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
		save () {
			this.$store.dispatch('alphataxcode/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			delete this.editedItem['updated_at'];
			delete this.editedItem['created_at'];
			this.editedItem.edit_by = this.currUser.id;
			if(this.valid){
				if (this.editedIndex > -1) {
					this.$store.dispatch('alphataxcode/updateAlphataxcode',this.editedItem);
				} else {
					this.$store.dispatch('alphataxcode/saveAlphataxcode',this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 3000);
			}
		},
      
	}
};
</script>